import React from 'react'
import logo from '../../images/pothead_logo.png'
import logo_header from '../../images/pothead_header.png'
import { Landing, LandingContainer, LandingLogo, LandingLinks, LandingLink, LandingHeader } from './LandingElements'

import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { fab } from '@fortawesome/free-brands-svg-icons'

library.add(fab);

const LandingSection = () => {
  return (
    <>

    <Landing>
        <LandingContainer>
            <LandingLogo src={logo}></LandingLogo>
            <LandingHeader src={logo_header} draggable="false"></LandingHeader>

            <p><b>Potheads</b> are a humble collection of unique and fun characters made up of various plant-themed traits. We are still gathering soil, planting seeds, and watering our friends so be sure to follow us on social media for updates.</p>
            
            <LandingLinks>
              <LandingLink href="https://twitter.com/PotHeadsGG"><FontAwesomeIcon icon={["fab", "twitter"]} /></LandingLink>
              <LandingLink href="https://potheads.gg/coming_soon"><FontAwesomeIcon icon={["fab", "discord"]} /></LandingLink>
            </LandingLinks>

        </LandingContainer>
    </Landing>

    </>
  )
}

export default LandingSection;