import './App.css';
import LandingSection from './components/LandingSection'

import leaf1 from './images/leaf_1.png'
import leaf2 from './images/leaf_2.png'
import leaf3 from './images/leaf_3.png'

function App() {
  return (
    <div className="App">

      <div class="anim-background">
      <LandingSection />
        <span><img src={leaf1} draggable="false"></img></span>
        <span><img src={leaf2} draggable="false"></img></span>
        <span><img src={leaf3} draggable="false"></img></span>
        <span><img src={leaf1} draggable="false"></img></span>
        <span><img src={leaf2} draggable="false"></img></span>
        <span><img src={leaf3} draggable="false"></img></span>
        <span><img src={leaf1} draggable="false"></img></span>
        <span><img src={leaf2} draggable="false"></img></span>
        <span><img src={leaf3} draggable="false"></img></span>
        <span><img src={leaf1} draggable="false"></img></span>
        <span><img src={leaf2} draggable="false"></img></span>
        <span><img src={leaf3} draggable="false"></img></span>
        <span><img src={leaf1} draggable="false"></img></span>
        <span><img src={leaf2} draggable="false"></img></span>
        <span><img src={leaf3} draggable="false"></img></span>
      </div>
    </div>
  );
}


export default App;
