import styled from 'styled-components'


/* GLOBAL */
export const Landing = styled.div`
    position: fixed;
    width: 100vw;
    height: 100vh;
    z-index: 1000;
`

/* CONTAINER */
export const LandingContainer = styled.div`
    height: 100vh;
    width: 400px;
    margin: 0 auto;
    text-align: center;

    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 10px;

    h1 {
      font-size: 3em;
      letter-spacing: 0.3em;
      text-indent: 0.3em;
      margin-bottom: 2px;

      @media (max-width: 760px) {
          text-indent: 0.1;
          font-size: 2.5em;
      }
      
    }

    @media (max-width: 760px) {
            width: 90%;
    }

`


/* POT HEADS LOGO */
export const LandingLogo = styled.img`
    height: auto;
    width: 235px;
    transition: 0.1s all ease-in-out;
    cursor: pointer;
    user-select: none;

    :hover, :active {
        width: 265px;
        animation: rotation 1s infinite linear;
    }

    @keyframes rotation {
      50% {
        transform: rotate(5deg);
      }
      100% {
        transform: rotate(0deg);
      }
    }
`

/* POT HEADS HEADER */
export const LandingHeader = styled.img`

    user-select: none;
    width: 235px;

    margin-top: 25px;
    margin-bottom: 5px;

`

/* FONT AWESOME ICONS */
export const LandingLinks = styled.div`

    display: flex;
    justify-content: center;
    gap: 30px;

`

export const LandingLink = styled.a`
    font-size: 2em;
    transition: 0.5s all ease-in-out;
    cursor: pointer;
    color: #2c1218;
    :hover, :active {
      font-size: 2.5em;
      color: #91a93a !important;
    } 

    :visited {
      color: #2c1218;
    }

`